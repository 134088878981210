<template>
	<div ref="calendarContainer">
	  <iframe ref="calendar" :src="this.calendarSrc" frameborder="0" scrolling="no"></iframe>
	</div>
  </template>
  
  <script>
  	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
  export default {
	data() {
	  return {
		calendarSrc: '',
		//calendarSrc: 'https://calendar.google.com/calendar/embed?src=c_ae5b1c32a936fe4bcc21724d60ac91aaf0540ae1fd93d8009452ceeba9f2fdae@group.calendar.google.com&ctz=America%2FNew_York&showTitle=0&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&mode=MONTH',
	  };
	},
	computed: {
		...mapState({
			buildingInfo: state => state.building.buildingInfo,
		})
	},
	async mounted() {
		await this.getBuildingInformation();
		this.calendarSrc = `https://calendar.google.com/calendar/embed?src=${this.buildingInfo.calendar_id}&ctz=America%2FNew_York&showTitle=0&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&mode=MONTH`
		window.addEventListener('resize', this.resizeIframe);
		this.resizeIframe();
		window.analytics.page('Calendar')
	},
	beforeDestroy() {
	  window.removeEventListener('resize', this.resizeIframe);
	},
	methods: {
	...mapActions({
			getBuildingInformation: 'building/getBuildingInformation',
		}),
	  resizeIframe() {
		
		const iframe = this.$refs.calendar;
		const container = document.querySelector('main.ant-layout-content');
		const height = container.offsetHeight;
		const width = container.offsetWidth;
		iframe.style.height = `${height}px`;
		iframe.style.width = `${width}px`;
	  },
	},
  };
  </script>
  
  <style scoped>
 .calendarHtml {
   width: 100%;
   height: 100%;
 } 
  </style>